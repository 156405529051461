<template>
    <div style="width: 100%">
        <el-descriptions class="margin-top" title="咨询详情" :column="3" :size="size" border style="width: 100%">
            <template slot="extra">
                <el-button type="primary" size="small" @click="goBack()">返回</el-button>
            </template>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i>
                    咨询人
                </template>
                {{ detail.consult_name }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-mobile-phone"></i>
                    联系方式
                </template>
                {{ detail.consult_mobile }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-date"></i>
                    咨询时间
                </template>
                {{ detail.created_at }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-office-building"></i>
                    咨询内容
                </template>
                {{ detail.content }}
            </el-descriptions-item>
        </el-descriptions>

    </div>
</template>

<script>
import {detailAPI} from './api'
export default {
    name: "Detail",

    data() {
        return {
            detail: {

            }
        }
    },

    mounted() {
        this.getDetail()
    },

    methods: {
        goBack() {
            this.$router.back()
        },

        async getDetail() {
            this.detail = await detailAPI(this.$route.params.id)
        },
    }
}
</script>

<style scoped>

</style>
